import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { EventInfo } from 'types/event'
import type { Entity, EntityType } from 'types/entity'

type initialStateType = {
  event: EventInfo | null,
  entitiesList: Entity[] | null,
  entityTypes: EntityType[] | null,
  isLoading: boolean,
  wasLoadedOnce: boolean,
}

const initialState: initialStateType = {
  event: null,
  entitiesList: null,
  entityTypes: null,
  isLoading: false,
  wasLoadedOnce: false,
}

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<EventInfo | null>) => {
      state.event = action.payload
    },
    setEntities: (state, action: PayloadAction<Entity[] | null>) => {
      state.entitiesList = action.payload
    },
    setEntityTypes: (state, action: PayloadAction<EntityType[] | null>) => {
      state.entityTypes = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setWasLoadedOnce: (state, action: PayloadAction<boolean>) => {
      state.wasLoadedOnce = action.payload
    },
  }
})


export const {
  setEvent,
  setEntities,
  setEntityTypes,
  setIsLoading,
  setWasLoadedOnce,
} = eventSlice.actions
